/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-08-27 18:28:23
 * @LastEditors: Please set LastEditors
 * @Description: 分利终端详情
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
// 导入自定义组件
import DealerDetails from './dealer-details.vue';
import DetailForm from './component/detail/detail-form.js';
import StatisicalForm from './component/statisical/statistical-form.js';

// 注册(自定义)组件
formCreate.component('DealerDetails', DealerDetails);
formCreate.component('DetailForm', DetailForm);
formCreate.component('StatisicalForm', StatisicalForm);

export default {
  extends: Form,
  components: {
    // 挂载组件
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'dealer-form',
      buttons: {
        submit: false,
        close: true,
        submitAudit: false,
      },
    };
  },
  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'dealer-detail' || item.field === 'dealer-statistics') {
        item.props = {
          ...item.props,
          rowData: this.formConfig,
        };
      }
      return item;
    },
    formComplete() {
      this.hiddenFields(true, 'dealer-statistics');
      const dealerForm = this.getRule('dealer-form');
      dealerForm.on = {
        ...dealerForm.on,
        tabName: (name) => {
          // 切换到‘基本信息’
          if (name === 'deatil') {
            this.hiddenFields(false, 'dealer-detail');
            this.hiddenFields(true, 'dealer-statistics');
          }
          // 切换到‘分利统计信息’
          if (name === 'statistics') {
            this.hiddenFields(false, 'dealer-statistics');
            this.hiddenFields(true, 'dealer-detail');
          }
        },
      };
    },

    // 提交
    submit() {},
  },
};
