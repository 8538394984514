/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-11-02 17:44:35
 * @FileName: 关联终端
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';

export default {
  name: 'Dealer',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/participator/terminal/findByDealerId',
      requestType: 'GET',
      params: {
        dealerId: this.subdata.customerCode,
      },
    };
  },
  components: {
    Modal,
  },
  props: {
    subdata: Object,
  },
  methods: {
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('dealer-detail-list');
  },
};
