<!--
 * @Description  :
 * @Version      : 2.0
 * @Autor        : fangzheng
 * @Date         : 2021-09-03 14:29:48
 * @LastEditors  : fangzheng
 * @LastEditTime : 2021-11-24 18:17:52
-->
<template>
  <div>
  <div class="text-contion"  v-for="(item, index) in dealerCommunicationsData" :key="index">

    <!-- <PersonInformationIitem :info="item"/> -->
      <el-row>
        <el-col style="margin-bottom: 22px;" :span="8">
          <el-form-item label="职位">
          <el-input
            disabled
            :value ="item.positionName"
          ></el-input>
          </el-form-item>
        </el-col>
        <el-col style="margin-bottom: 22px;" :span="8">
          <el-form-item label="用户名">
          <el-input
            disabled
            :value ="item.userName"
          ></el-input>
          </el-form-item>
        </el-col>
        <el-col style="margin-bottom: 22px;" :span="8">
          <el-form-item label="所属组织">
          <el-input
            disabled
            :value ="item.communicationOrgName"
          ></el-input>
          </el-form-item>
        </el-col>
      <el-row>
      </el-row>
        <el-col style="margin-bottom: 22px;" :span="8">
          <el-form-item label="联系方式">
          <el-input
            disabled
            :value ="item.communicationContact"
          ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
  </div>

  </div>
</template>

<script>

// import PersonInformationIitem from './person-information-item.vue';

export default {
  components: {
    // PersonInformationIitem,
  },
  watch: {
    dealerCommunicationsData(val) {
      console.log(val);
    },
  },
  props: {
    dealerCommunicationsData: Array,
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
</style>
