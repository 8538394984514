var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.dealerCommunicationsData, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "text-contion" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "22px" },
                  attrs: { span: 8 },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "职位" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", value: item.positionName },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "22px" },
                  attrs: { span: 8 },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户名" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", value: item.userName },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "22px" },
                  attrs: { span: 8 },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属组织" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: "",
                          value: item.communicationOrgName,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-row"),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "22px" },
                  attrs: { span: 8 },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系方式" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: "",
                          value: item.communicationContact,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }