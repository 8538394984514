/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-09-10 14:59:38
 * @LastEditors: Please set LastEditors
 * @Description: 准入已有终端/选择终端列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';

export default {
  name: 'existingDealerslist',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/mdm/dealer/findByConditions',
      requestType: 'GET',
      isCalculateHeight: false,

    };
  },
  props: {
    rowData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    Modal,
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'check-all') {
        // 全部全选
        this.$refs.xTable.setAllCheckboxRow(true);
      }
      if (val.code === 'current-page') {
        // 本页全选
        this.$refs.xTable.setAllCheckboxRow(true);
        this.selectRow = this.tableData;
      }
    },
    // 设置下拉选项的值
    setColumn(v) {
      const rowData = v;
      // 所属组织
      if (rowData.field === 'orgName') {
        rowData.search = 'true';
        rowData.title = '所属组织';
        rowData.type = 'select';
        rowData.apiUrl = '/mdm/baseTreeController/orgTree';
        rowData.optionsKey = {
          label: 'name',
          value: 'id',
        };
        rowData.isSearch = true;
        rowData.paramsName = 'name';
        rowData.paramsFixed = {
          enableStatus: '009',
        };
      }
      return rowData;
    },
  },
  created() {
    // 重写获取配置信息

    this.getConfigList('existing_dealers_list');
  },
};
