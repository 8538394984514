/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-11-02 20:17:07
 * @LastEditors: Please set LastEditors
 * @Description: 分利经销商列表
 */
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Modal from '@/found/components/modal';
import Form from './form';
import ExistingDealers from './form/component/existing_dealers';

export default {
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/participator/dealer/findByConditions',
      requestType: 'GET',
    };
  },
  components: {
    Modal,
    Form,
    ExistingDealers,
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      let urls = '';
      let text = '';
      // 点击编辑按钮传递数据到表单
      this.formConfig = {
        ...this.formConfig,
        code: val.code,
      };
      if (val.code === 'dealers') {
        // 打开准入已有终端页
        this.modalConfig.title = '准入已有经销商';
        this.formName = 'ExistingDealers';
        this.formConfig.row = row;
        this.formConfig.buttonCode = 'dealers';
        this.openFull();
      } else if (val.code === 'particulars') {
        this.modalConfig.title = '分利经销商详情';
        this.formConfig = row;
        this.formConfig.buttonCode = 'particulars';
        this.openFull();
      } else if (val.code === 'view') {
        // 查看
        this.modalConfig.title = '查看';
        this.formConfig = {
          ...row,
          btnCode: 'particulars',
          code: 'particulars',
        };
        this.openFull();
      } else if (val.code === 'edit') {
        // 编辑
        this.modalConfig.title = '编辑';
        this.formConfig.type = val.code;
        this.formConfig.id = row.id;
        this.formName = 'EditForm';
        this.openModal();
      } else if (val.code === 'enable' || val.code === 'span-qi') {
        // 启用
        urls = '/cps/v1/participator/dealer/enable';
        text = '启用';
        this.selection.push(row.id);
        this.operationClick(urls, text);
      } else if (val.code === 'disable' || val.code === 'span-jin') {
        // 禁用
        urls = '/cps/v1/participator/dealer/disable';
        text = '禁用';
        this.selection.push(row.id);
        this.operationClick(urls, text);
      }
    },
    // 启用、禁用操作
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.patch(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
    setColumn(v) {
      const rowData = v;
      // 所属组织
      // if (rowData.field === 'orgCode') {
      //   rowData.search = 'true';
      //   rowData.title = '所属组织';
      //   rowData.type = 'select';
      //   rowData.apiUrl = '/mdm/baseTreeController/orgTree';
      //   rowData.optionsKey = {
      //     label: 'name',
      //     value: 'code',
      //     children: 'children',
      //   };
      //   rowData.isSearch = true;
      //   rowData.paramsName = 'name';
      //   rowData.paramsFixed = {
      //     enableStatus: '009',
      //   };
      // }
      // 所属客户组织
      // if (rowData.field === 'customerOrgCode') {
      //   rowData.search = 'true';
      //   rowData.title = '所属客户组织';
      //   rowData.type = 'select';
      //   rowData.apiUrl = '/mdm/baseTreeController/customerOrgTree';
      //   rowData.optionsKey = {
      //     label: 'name',
      //     value: 'code',
      //     children: 'children',
      //   };
      //   rowData.isSearch = true;
      //   rowData.paramsName = 'name';
      //   rowData.paramsFixed = {
      //     enableStatus: '009',
      //   };
      // }

      return rowData;
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('dealer-list');
  },
};
