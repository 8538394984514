import { render, staticRenderFns } from "./person-information.vue?vue&type=template&id=ccd8284e&scoped=true&"
import script from "./person-information.vue?vue&type=script&lang=js&"
export * from "./person-information.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccd8284e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ccd8284e')) {
      api.createRecord('ccd8284e', component.options)
    } else {
      api.reload('ccd8284e', component.options)
    }
    module.hot.accept("./person-information.vue?vue&type=template&id=ccd8284e&scoped=true&", function () {
      api.rerender('ccd8284e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "found/views/cps/views/participant/dealer/form/component/detail/person-information.vue"
export default component.exports