/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-09-10 15:00:35
 * @LastEditors: Please set LastEditors
 * @Description: 准入已有终端页
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import DealersList from './dealers-list';

formCreate.component('dealersList', DealersList);

export default {
  name: 'ExistingDealers',
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'existing_dealers_form',
    };
  },
  methods: {
    // 提交
    submit() {
      const dealersListEl = this.fApi.el('dealersList');
      console.log(dealersListEl);
      const formData = dealersListEl.selectRow;

      if (formData) {
        const url = '/cps/v1/participator/dealer/createBatch';
        const params = formData;
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '保存成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
