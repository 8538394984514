/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime : 2021-11-24 17:09:00
 * @LastEditors  : fangzheng
 * @Description: 分利经销商/基础信息
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import DealerDetailList from './dealer-detail-list.js';
import PersonInformation from './person-information.vue';

formCreate.component('DealerDetailList', DealerDetailList);
formCreate.component('PersonInformation', PersonInformation);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  props: {
    rowData: Object,
  },
  data() {
    return {
      buttons: {
        submit: false, // 提交按钮
        close: false, // 关闭按钮
        submitAudit: false, // 报错并提交按钮
      },
      formFunctionCode: 'dealer-detail',
      fields: [
        'customerCode',
        'customerName',
        'customerType',
        'orgName',
        'customerOrgName',
        'channelName',
        'provinceName',
        'cityName',
        'districtName',
        'legalRepresent',
        'customerContact',
        'contactPersonPhone',
        'registeredAddress',
        'dealerContactPersons',
        'contactPersonName',
        'contactPersonPhone',
        'dealerCommunication',
        'positionName',
        'userName',
        'communicationOrgName',
        'communicationContact',
      ],
    };
  },
  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'DealerDetailList') {
        item.props = {
          ...item.props,
          subdata: this.rowData,
        };
      }
      return item;
    },
    getDealerData() {
      request
        .get('/cps/v1/participator/dealer/findDetailById', { id: this.rowData.id })
        .then((res) => {
          if (!res.success) return;
          const obj = res.result;
          // if (obj.dealerCommunication) {
          //   obj.positionName = obj.dealerCommunication.positionName;
          //   obj.userName = obj.dealerCommunication.userName;
          //   obj.communicationOrgName = obj.dealerCommunication.communicationOrgName;
          //   obj.communicationContact = obj.dealerCommunication.communicationContact;
          // }
          if (obj.dealerCommunications) {
            const dealerCommunications = this.getRule('dealerCommunications');
            dealerCommunications.props = {
              ...dealerCommunications.props,
              dealerCommunicationsData: obj.dealerCommunications,
            };
            // obj.dealerCommunications.forEach((item) => {
            //   obj.positionName = item.positionName;
            //   obj.userName = item.userName;
            //   obj.communicationOrgName = item.communicationOrgName;
            //   obj.communicationContact = item.communicationContact;
            // });
          }
          if (obj.dealerContactPersons) {
            obj.dealerContactPersons.forEach((item) => {
              obj.contactPersonName = item.contactPersonName;
              obj.contactPersonPhone = item.contactPersonPhone;
            });
          }
          console.log(obj);
          this.setValue({
            ...obj,
          });
        });
    },

    formComplete() {
      this.disabled(true, this.fields);
      this.getDealerData();
    },

    // 提交
    submit() {},
  },
};
